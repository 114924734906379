import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import '../css/card.css'
import consulting from '../assets/tech-consulting.png';
import robot from '../assets/robot.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import nba from '../assets/nba.png';
import sap from '../assets/sap.png';
import nyc from '../assets/open-data.png';
import shopify from '../assets/shopify-bag.png';
import {Position, CornerDialog, Pane} from 'evergreen-ui'

class HomeCards extends Component {
  constructor(props){
    super(props);
    this.state = {
      dialogOneIsShown: false,
      dialogOneHasCancel:false,
      dialogTwoIsShown: false,
      dialogTwoHasCancel:false,
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}


  render() {
    return(
      <div data-aos="fade-down" id="cardDeck" style={{background: 'rgb(236,236,236)',
      background: 'linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 48%, rgba(236,236,236,1) 100%)'}}>
      <CardDeck>

      <div id="card1" style={{
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: 500,
      margin: '0  auto',
      paddingBottom: '30px'
      }}>

  <Card bsPrefix="homeCard">
  <Card.Img variant="top" src={consulting}/>
  <Card.Body>
    <Card.Title style={{textAlign:'center'}}>Low Cost Tech Consulting</Card.Title>
    <Card.Text style={{textAlign:'center'}}>
    Want to gain a competitive edge by using tech solutions to grow and optimize businesses?
    Our team at Greenstar Tech understands the financial blockades that your business is facing which prevents effective competition with more well-established businesses.
    </Card.Text>

    <div id ="dialog">
    <CornerDialog
           title="Schedule a Consultation Now!"
           isShown={this.state.dialogOneIsShown}
           onCloseComplete={() => this.setState({dialogOneIsShown:false})}
           onConfirm={()=> window.location.href = "/services"}
           width={"50%"}
           hasCancel={this.state.dialogOneHasCancel}
           intent={'success'}
           confirmLabel={"Schedule Now!"}
         >
           Greenstar Tech offers <b>free</b> tech consultanting based on the complexity of the underlying infrastructure! See if Greenstar Tech can revitalize your business today!
         </CornerDialog>
         </div>

    <Button variant="primary" style={{
      display: 'flex',
      justifyContent: 'flex-begin',
      margin: '0  auto'
    }} onClick={() => this.setState({dialogOneIsShown:true}) }>Read More...</Button>

    <div id ="sep" style={{paddingBottom:'10px'}}>
    </div>

    <Button style={{
      display: 'flex',
      justifyContent: 'flex-end',
      margin: '0  auto',
      backgroundColor: '#00cc00',
      borderStyle: 'none'}} onClick={()=> window.location.href = "/services"}>Schedule a Consultation</Button>
  </Card.Body>
</Card>
</div>




<div id="card2" style={{
display: 'flex',
justifyContent: 'flex-end',
maxWidth: 500,
margin: '0  auto',
}}>
<Card bsPrefix="homeCard">
<Card.Img  variant="top" src={robot}/>
<Card.Body>
<Card.Title style={{textAlign:'center'}}>High Level, Scalable Automation</Card.Title>
<Card.Text style={{textAlign:'center'}}>
Is a component of your business reliant on a repetitive process? For example, take a shopping website who has to price listings based on their competitors. A manual implementation of this task will be too time-consuming. Leveraging roboots to perform this task can improve efficiency and productivity!
</Card.Text>


<div id ="dialog">
<CornerDialog
       title="Automation Examples"
       isShown={this.state.dialogTwoIsShown}
       onCloseComplete={() => this.setState({dialogTwoIsShown:false})}
       onConfirm={()=> window.location.href = "/services"}
       width={"75%"}
       hasFooter={this.state.dialogTwoHasCancel}
       intent={'success'}
       confirmLabel={"Schedule Now!"}
     >
     <ul>
<li>NBA Advanced Analytics</li>
<li>Enterprise Grade E-Commerce Scraping Solutions</li>
<li>New York State Small Business Dataset Analytics</li>
<li>Phone Automation / Verification Solutions </li>
</ul>
<div id="icons">
<img
 src={nba}
 id="logo"
 width="50"
 height="50"
 bsPrefix=""
 alt="NBA"
/>
<img
 src={sap}
 id="logo"
 width="50"
 height="50"
 bsPrefix=""
 alt="SAP Analytics"
/>
<img
 src={nyc}
 id="logo"
 width="50"
 height="50"
 bsPrefix=""
 alt="NYC Open Data"
/>
<img
 src={shopify}
 id="logo"
 width="50"
 height="50"
 bsPrefix=""
 alt="Shopify"
/>
</div>
     </CornerDialog>
     </div>
<Button variant="primary" style={{
  display: 'flex',
  justifyContent: 'flex-begin',
  margin: '0  auto',
}} onClick={() => this.setState({dialogTwoIsShown:true}) }>Examples</Button>
</Card.Body>
</Card>
</div>
</CardDeck>
</div>
    );

  }
}

export default HomeCards;
