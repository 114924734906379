import Carousel from 'react-bootstrap/Carousel';
import React, {Component} from 'react';
import webdev from '../assets/webdev.jpg';
import nyc from '../assets/nyc-large.gif';
import hardware from '../assets/hardware.jpg';
import software from '../assets/software.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";

class CarouselMain extends Component {
  constructor(props){
    super(props);
    this.state = {
     prevIcon: <span className="fas fa-chevron-left" style={{fontSize:24, color: 'black'}}></span>,
     nextIcon: <span className="fas fa-chevron-right" style={{fontSize:24, color:'black'}}></span>
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render() {

  const {nextIcon, prevIcon} = this.state;
  return(
    <div data-aos="fade-down">
<Carousel nextIcon = {nextIcon} prevIcon={prevIcon}  variant="dark" fade>
  <Carousel.Item>
    <img
      className="d-block w-100"
      width={800}
      height={500}
      src={nyc}
      alt="New York City based."
    />
    <Carousel.Caption>
      <h3 style={{textAlign: 'center'}}>New York City based hardware, software and web development company</h3>
      <p style={{textAlign: 'center'}}>Affordable consulting, hosting, development and deployment of technological services</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      width={800}
      height={500}
      src={hardware}
      style={{filter: 'blur(1px)'}}
      alt="Hardware Development"
    />

    <Carousel.Caption>
      <h3 style={{textAlign: 'center'}}>Hardware Engineering</h3>
      <p>Embedded systems, Circuit Design and Analysis, Schematics, Datasheets, Microcontrollers</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      width={800}
      height={500}
      style={{filter: 'blur(4px)'}}
      src={webdev}
      alt="Web Development"
    />

    <Carousel.Caption>
      <h3 style={{textAlign: 'center'}, {color: 'black'}}>Fullstack Development</h3>
      <p style={{textAlign: 'center'}, {color: 'black'}}>Front-End Development, Backend Development, Fullstack Project Managment, Databases, Web Security</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      width={800}
      height={500}
      style={{filter: 'blur(3px)'}}
      src={software}
      alt="Software Engineering"
    />

    <Carousel.Caption>
      <h3 style={{textAlign: 'center'}}>Software Engineering</h3>
      <p style={{textAlign: 'center'}}>Cross-Platform Applications, GUI Applications, Embedded Web Applications, Data Extraction and Retrieval</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
</div>
);
}
}

export default CarouselMain;
