import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card';
import api from '../assets/api-creation.png';
import database from '../assets/serverManagement.png';
import security from '../assets/database-security.png';
import mongodb from '../assets/mongodb.png';
import redis from '../assets/redis.png';
import node from '../assets/nodejs.png';

class BackendCards extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){

  return(
    <div data-aos="zoom-in-up" style={{paddingLeft:-'-1000%'}}>
    <CardDeck bsPrefix="servicesCardDeck">
    <Card bsPrefix="servicesCard">
  <Card.Body>
  <img
   src={database}
   id="cardIcons"
   width="50"
   height="50"
   bsPrefix=""
   alt="Applications"
  />
  <Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
    Database Management
  </Card.Title>
  </Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={security}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Paypal"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Industry Compliant Database Security
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={api}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Security"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  API Creation & Management
</Card.Title>
</Card.Body>
</Card>

</CardDeck>


<CardDeck bsPrefix="servicesCardDeck">
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={mongodb}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="mongodb"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
MongoDB Database Management
</Card.Title>
</Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={redis}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Redis"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Redis Database Management
</Card.Title>
</Card.Body>
</Card>


</CardDeck>


    </div>
  );
}
}

export default BackendCards;
