import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card';
import nba from '../assets/documentation.png';
import pi from '../assets/pi-zero.jpg';
import arduino from '../assets/arduino.jpg';
import arm from '../assets/arm.jpg';

class HardwareEngineeringCards extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){

  return(
    <div data-aos="zoom-in-up">
    <CardDeck bsPrefix="servicesCardDeck">
    <Card bsPrefix="servicesCard">
  <Card.Body>
  <img
   src={arm}
   id="cardIcons"
   width="50"
   height="50"
   bsPrefix=""
   alt="ARM Architecture"
  />
  <Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
    ARM Architecture Programming
  </Card.Title>
  </Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={arduino}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Arduino"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Arduino Protyping
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={pi}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Raspberry Pi Zero"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Raspberry Pi-Zero Embedded Devices
</Card.Title>
</Card.Body>
</Card>

</CardDeck>
    </div>
  );
}
}

export default HardwareEngineeringCards;
