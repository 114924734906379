import React, {Component} from 'react';
import '../css/about.css'
import vector from '../assets/vector.jpg';
import AOS from 'aos';
import "aos/dist/aos.css";
import Carousel from 'react-bootstrap/Carousel';

class About extends Component {
  constructor(props){
    super(props);
    this.state = {
      prevIcon: <span className="fas fa-star" style={{fontSize:24, color: 'green', opacity:0.5}}></span>,
      nextIcon: <span className="fas fa-star" style={{fontSize:24, color:'green', opacity:0.5}}></span>
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

  render(){
    const {nextIcon, prevIcon} = this.state;
    return(
      <div id="aboutWrapper">

      <div data-aos-offset="0" data-aos="fade-up" id="about" style={ {paddingTop:20}}>
      <div className="title">
      <h2 style={{fontWeight:"bold"}}>ABOUT    <hr style={{width:'5%'}} /></h2>

      </div>


      <div className="d-flex justify-content-center align-items-center">
      <div className="mission">
      <h3 style={{fontWeight:"bold"},{textAlign:'center'}}> Bridging the technological gap </h3>
      <p style={{paddingLeft:10}}> Performing undergraduate research at the University at Buffalo identified sociological ramifications due to the increase of technology usage. There is now a dependency on technology, but there is an evident disparity of technological knowledge. Principles that are integral to programmers, such as “information hiding,” are weaponized far more insidiously in the industry. First, to gate keep information by keeping the core aspects of the program hidden to the customer. Abstracting implementation details allows them to charge customers more since the customer does not know what they are specifically paying for. We at Greenstar Tech intend to bridge the technological gap. <b> Technology is an integral facet to our society and we will not allow financial blockades to restrict technology access and services. </b></p>
      </div>

      <img
       src={vector}
       id="vector"
       bsPrefix=""
       alt="Calculations"

     />
      </div>
      </div>

      <div data-aos-offset="0" data-aos="fade-up" id="facts" style={ {paddingTop:20}}>
      <div className="title">


      <h2 style={{fontWeight:"bold"}}>RELEVANT INFORMATION<hr style={{width:'25%'}} /></h2>
      <div id="carousel">
      <Carousel nextIcon = {nextIcon} prevIcon={prevIcon}  variant="dark" indicators={false}>
      <Carousel.Item>
          <h3 style={{color: 'black'}, {fontSize: 17}}>"The Internet can be a “great equalizer” – small businesses with minimal startup costs can now attempt to compete effectively against large, well-established companies"</h3>
          <p style={{color: 'black'}}><a href={"https://www.igi-global.com/gateway/article/3496"} target="_blank" rel="noopener noreferrer"> Mira Kartiwi, Robert Macgregor - Journal of Electronic Commerce </a> & The Small Business Authority, 2012</p>
      </Carousel.Item>
      <Carousel.Item>
          <h3 style={{color: 'black'}, {fontSize: 17}}>"Small businesses have begun to see the true benefits of being connected and are growing on average forty-six percent (46%) quicker than businesses not using the Internet"</h3>
          <p style={{color: 'black'}}><a href={"https://www.researchgate.net/publication/281365403_An_Exploratory_Study_on_Small_Business_Website_Creation_and_Usage"} target="_blank" rel="noopener noreferrer"> Chuleeporn Changchit, Tim Klaus - Texas A&M University Corpus Christi - An Exploratory Study on Small Business Website Creation and Usage </a> </p>
      </Carousel.Item>
      </Carousel>
      </div>
      </div>

      </div>
      </div>
    );
  }
}
export default About;
