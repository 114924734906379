import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card';
import business from '../assets/business.png';
import paypal from '../assets/paypal.png';
import security from '../assets/security.png';
import shopping from '../assets/shopping.png';
import angular from '../assets/angular.png';
import react from '../assets/react.png';
import vue from '../assets/vue.png';
import visa from '../assets/Visa-Logo.png';
import btc from '../assets/btc.png'
class WebdevCards extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){

  return(
    <div data-aos="zoom-in-up">
    <CardDeck bsPrefix="servicesCardDeck">
    <Card bsPrefix="servicesCard">
  <Card.Body>
  <img
   src={business}
   id="cardIcons"
   width="50"
   height="50"
   bsPrefix=""
   alt="Business"
  />
  <Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
    Web Development for Businesses
  </Card.Title>
  </Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={paypal}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Paypal"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Paypal Integration
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={security}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Security"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Security of Authorized and Protected Data
</Card.Title>
</Card.Body>
</Card>

</CardDeck>

<CardDeck bsPrefix="servicesCardDeck">
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={shopping}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="ARM Architecture"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
E-Commerce Websites
</Card.Title>
</Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={btc}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Bitcoin"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Cryptocurrency Payment Integration
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={visa}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Visa"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Major Debit / Credit Payment Processors
</Card.Title>
</Card.Body>
</Card>

</CardDeck>

<CardDeck bsPrefix="servicesCardDeck">
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={vue}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Vue"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Vue Progressive Framework
</Card.Title>
</Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={react}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="React"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
React UI Framework
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={angular}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Angular.js"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Angular Development Framework
</Card.Title>
</Card.Body>
</Card>

</CardDeck>
    </div>
  );
}
}

export default WebdevCards;
