import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEnvelope, faPhone, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import "aos/dist/aos.css";

class Map extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    var setting = {"height":500,"width":800,"zoom":16,"queryString":"11414 Sutphin Boulevard, Jamaica, NY, USA","place_id":"ChIJJUY_nNVmwokRK3lRG3hvyVo","satellite":true,"centerCoord":[40.685876763652416,-73.79371124999999],"cid":"0x5ac96f781b51792b","lang":"en","cityUrl":"/us/ny/new-york","cityAnchorText":"Map of New York, New York State, United States","id":"map-9cd199b9cc5410cd3b1ad21cab2e54d3","embed_id":"522924"};
    const script = document.createElement("script");
      script.type = 'text/javascript';
     script.async = true;
     script.src = "https://1map.com/js/script-for-user.js?embed_id=522924";
     script.onload = () => window.OneMap.initMap(setting);
     document.getElementById('map-9cd199b9cc5410cd3b1ad21cab2e54d3').appendChild(script);
       window.addEventListener('load',function(){
         AOS.init();
   })
 }
 render(){
   return(
     <div id="wrapper" data-aos="fade-up">

     <div id="contact"  style={{paddingTop:10, paddingRight:10}}>
     <h1 style={{paddingBottom:10, textAlign:"center"}}> New York Office</h1>
     <h2 style={{paddingBottom:10,textAlign:"center",paddingLeft:'20px'}}>  <FontAwesomeIcon style={{color: "green", fontSize:20, position: "relative", bottom: 3, right:4}} icon={faMapMarkerAlt}/> 114-14 Sutphin Boulevard, Jamaica, NY 11434</h2>
     <h2 style={{paddingBottom:10,textAlign:"center"}}> <FontAwesomeIcon style={{color: "green", fontSize:20, position: "relative", right: 5}} icon={faEnvelope}/> nyc@greenstar-tech.net</h2>
     <h2 style={{paddingBottom:10,textAlign:"center" }}> <FontAwesomeIcon style={{color: "green", fontSize:20, position: "relative", bottom: 4, right:5}} icon={faPhone}/> (347) 456-6453</h2>
     </div>


     <div id="wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3">
     <div id="map-9cd199b9cc5410cd3b1ad21cab2e54d3">
     </div>
     </div>

     </div>
   );
 }
}
export default Map;
