import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card';
import data from '../assets/data.png';
import dataExtraction from '../assets/data-extraction.png';
import mobileApp from '../assets/mobile-application.png';
import applications from '../assets/applications.png';
import java from '../assets/java.png';
import cpp from '../assets/c++.png';
import python from '../assets/python.png';
import golang from '../assets/golang.png';
import node from '../assets/nodejs.png';
class SoftwareCards extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){

  return(
    <div data-aos="zoom-in-up">
    <CardDeck bsPrefix="servicesCardDeck">
    <Card bsPrefix="servicesCard">
  <Card.Body>
  <img
   src={applications}
   id="cardIcons"
   width="50"
   height="50"
   bsPrefix=""
   alt="Applications"
  />
  <Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
    Desktop Applications
  </Card.Title>
  </Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={dataExtraction}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Paypal"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Data Analytics
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
 src={data}
 id="cardIcons"
 width="50"
 height="50"
 bsPrefix=""
 alt="Security"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
  Data Retrieval & Data Extraction
</Card.Title>
</Card.Body>
</Card>

</CardDeck>

<CardDeck bsPrefix="servicesCardDeck">
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={cpp}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="C++"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
C++ Programming
</Card.Title>
</Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={python}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Python"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Python Programming
</Card.Title>
</Card.Body>
</Card>


<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={java}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Java"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Java Programming
</Card.Title>
</Card.Body>
</Card>

</CardDeck>

<CardDeck bsPrefix="servicesCardDeck">
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={golang}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="golang"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Go Programming
</Card.Title>
</Card.Body>
</Card>

<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={node}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Node.js"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Node.js Programming
</Card.Title>
</Card.Body>
</Card>
<Card bsPrefix="servicesCard">
<Card.Body>
<img
src={mobileApp}
id="cardIcons"
width="50"
height="50"
bsPrefix=""
alt="Mobile Applications"
/>
<Card.Title style={{textAlign:"center", paddingTop:'10px'}}>
Mobile Apps
</Card.Title>
</Card.Body>
</Card>

</CardDeck>


    </div>
  );
}
}

export default SoftwareCards;
