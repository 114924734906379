import Button from 'react-bootstrap/Button'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import '../css/pill.css'
import greenstar from '../assets/greenstarlogo2.png';
import React, {Component} from 'react';
import { withRouter } from "react-router";
import {motion } from "framer-motion"
class NavBar extends React.Component{
  constructor(props){
    super(props);
  }

render(){
  const {location} = this.props;
  return(
    <Navbar bg="light" expand="lg">
    <Navbar.Brand href="/">
    <motion.div whileHover={{
    rotate: 360,
}} whileTap={{ scale: 0.5 }} transition={{
  duration: 1,
  repeatDelay: 1
}} >
    <img
     src={greenstar}
     id="logo"
     width="50"
     height="50"
     bsPrefix=""
     alt="Greenstar Tech Logo"
   />
   </motion.div>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav fill variant="pills" defaultActiveKey={location.pathname} className="w-100 nav-justified">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/about">About Us</Nav.Link>
        <Nav.Link href="/services">Services</Nav.Link>
        <Nav.Link eventKey="disabled" disabled>News</Nav.Link>
        <Nav.Link eventKey="disabled" disabled>Blog</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
)};
}

export default withRouter(NavBar)
