import './App.css';
import TopBar from "./components/Navbar.js"
import Slider from "./components/Carousel.js"
import HomeCards from "./components/HomeCards.js"
import Map from "./components/Map.js"
import Services from "./components/Services.js"
import About from "./components/About.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'
import './css/map.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { withRouter } from "react-router";
function App() {
  return (
    <div>
    <Router>


    <Switch>
    <Route exact path="/">
    <TopBar />
    <Slider />
    <HomeCards />
    <Map />
    </Route>
          <Route path="/about">
          <TopBar />
          <About />
          </Route>
          <Route path="/services">
          <TopBar />
          <Services />
          </Route>
    </Switch>
    </Router>
    </div>
  );
}

export default App
