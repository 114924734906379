import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import '../css/contact.css'
import Alert from 'react-bootstrap/Alert';
import ReCAPTCHA from "react-google-recaptcha";
import Spinner from 'react-bootstrap/Spinner'
const validator = require("email-validator");
const axios = require('axios');

class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {
      contactState:-1,
      name: '',
      email: '',
      message:'',
      recaptcha: '',
      showError:null,
      error:-1
    }
  }


  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){
  var alert = null;
  if(this.state.showError === true){
    if(this.state.error === 1){
       alert = <Alert  data-aos="flip-down" variant="danger" onClose={() => this.setState({showError:false})} dismissible>
        <Alert.Heading style={{textAlign:"center"}}>Malformed Email </Alert.Heading>
        <p style={{textAlign:"center"}}>Please check email and resubmit!
        </p>
      </Alert>
    }
    if(this.state.error === 2){
      alert = <Alert data-aos="flip-down" variant="danger" onClose={() => this.setState({showError:false})} dismissible>
       <Alert.Heading style={{textAlign:"center"}}>Please Enter a Valid Name! </Alert.Heading>
       <p style={{textAlign:"center"}}>Please check name and resubmit!</p>
     </Alert>
    }
    if(this.state.error === 3){
      alert = <Alert data-aos="flip-down"  variant="danger" onClose={() => this.setState({showError:false})} dismissible>
       <Alert.Heading style={{textAlign:"center"}}>Message Has No Content!</Alert.Heading>
       <p style={{textAlign:"center"}}>Please enter message and resubmit!</p>
     </Alert>
    }
    if(this.state.error === 4){
      alert = <Alert data-aos="flip-down"  variant="danger" onClose={() => this.setState({showError:false})} dismissible>
       <Alert.Heading style={{textAlign:"center"}}>Please submit the ReCAPTCHA!</Alert.Heading>
       <p style={{textAlign:"center"}}>Retry ReCAPTCHA and try again!</p>
     </Alert>
    }
  }

  const contactBox = () => {
      switch(this.state.contactState) {
        case 0:       return(  <div data-aos="zoom-out"><h1 style={{  margin: '0',
            padding: '34px 24px 12px',
            fontWeight: '300',
              fontSize: '40px',
              textAlign:'center'}}>Consultation Successfully Scheduled!</h1></div>)
        case 1:       return(<div class="d-flex justify-content-center">
          <Spinner animation="border" size="lg" variant="success" />
          </div>
        )
        default:      return (
      <div id="contactBox" data-aos="zoom-in-up">
      <div className="wrapper">
      <div className="header">
        <h1 style={{  margin: '0',
          padding: '34px 24px 12px',
          fontWeight: '300',
          fontSize: '40px'}}>Schedule a Consultation Now!</h1>
        <p style={{margin: '0',
          padding: '0 40px',
          paddingBottom:'20px',
          fontSize: '16px',
          width: '360px',
          opacity: '0.8',
          margin: '0 auto'}}>Please state your technical inquiry to the best of your abilities below!</p>
      </div>

      <form id="contactForm" onSubmit={(e)=>{
        e.preventDefault();
        var nameLength = this.state.name.length;
        var contentLength = this.state.message.length;
        var captchaLength = this.state.recaptcha.length;
        if(!validator.validate(this.state.email)){
          this.setState({showError:true,error:1});
          return;
        }
        if(nameLength <= 0 || nameLength > 25){
          this.setState({showError:true,error:2});
          return;
        }
        if(contentLength <= 0 || contentLength > 300){
          this.setState({showError:true,error:3});
          return;
        }
        if(captchaLength <= 0){
          this.setState({showError:true,error:4});
          return;
        }
        this.setState({contactState:1});
        const data = {name : this.state.name, email: this.state.email, message: this.state.message, captcha: this.state.recaptcha}
        axios.post('/api/contact/',data)
        .then((response)=>{
          this.setState({contactState:0});
        })
      }}>
        <h4 style={{  color: '#666',
          margin: '16px 0 6px',
          fontSize: '16px',
          letterSpacing: '1px'}}>Name</h4>
        <input id="contactInput" type="text" name="name" placeholder="Name" onChange={e => this.setState({name:e.target.value})} />
        <h4 style={{  color: '#666',
          margin: '16px 0 6px',
          fontSize: '16px',
          letterSpacing: '1px'}}>Email</h4>
        <input id="contactInput" type="text" name="email" placeholder="Email" onChange={e => this.setState({email:e.target.value})}/>
        <h4 style={{  color: '#666',
          margin: '16px 0 6px',
          fontSize: '16px',
          letterSpacing: '1px'}}>Message</h4>
        <textarea id="contactText" placeholder="Enter Message Here" onChange={e => this.setState({message:e.target.value})}></textarea>
        <ReCAPTCHA
        sitekey="6Lc0wkQbAAAAAH16s6If_dny1by0indTXMl3i0bG"
        onChange={(value)=>{
          this.setState({recaptcha:value});
        }}
        onExpired={()=>{
          this.setState({recaptcha:''});
        }}
        onErrored={()=>{
          this.setState({recaptcha:''});
        }}
        />,
        <button type="submit" id="send">Send</button>
      </form>
    </div>
  </div>);
  }
}

  return(
    <div>
    <div id="contactAlert" style={{paddingTop:"20px"}}>
    {alert}
    </div>

    <div>
    {contactBox()}
    </div>
</div> );
}

}
export default Contact;
