import React, {Component} from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import {AnnotationIcon, ApplicationsIcon, Button, ControlIcon, DatabaseIcon, WrenchIcon} from 'evergreen-ui'
import '../css/services.css'
import HardwareEngineeringCards from "./HardwareEngineeringCards.js"
import WebdevCards from "./WebdevCards.js"
import SoftwareCards from "./SoftwareCards.js"
import BackendCards from "./BackendCards.js"
import Contact from "./Contact.js"
class Services extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentService: -1
    }
  }

  componentDidMount() {
    window.addEventListener('load',function(){
      AOS.init();
    });
}

render(){
  var button = null;
  if(this.state.currentService !== -1){
    button = <Button data-aos="fade-down" onClick={() => this.setState({currentService:-1}) } appearance="primary" intent="success" height={48} marginY={8}  iconAfter={AnnotationIcon} className="service-button">Schedule Consultation</Button>;
  }
  const currentService = () => {
      switch(this.state.currentService) {
        case 1:   return <HardwareEngineeringCards />;
        case 2:   return <WebdevCards />
        case 3:   return <SoftwareCards />
        case 4:   return <BackendCards />
        default:      return (<Contact />);
      }
    }


  return(
    <div id = "services" data-aos="fade-down" >
    <div id = "buttons" >

    <Button onClick={() => this.setState({currentService:2}) } size="large" marginY={8}  iconAfter={ControlIcon} className="service-button">
    <div>
        Web Development / Full-Stack Development
        </div>
      </Button>
    <Button onClick={() => this.setState({currentService:1}) } size="large" marginY={8}  iconAfter={WrenchIcon} className="service-button">
    <div>
        Hardware Engineering
        </div>
      </Button>
      <Button onClick={() => this.setState({currentService:3}) } size="large" marginY={8} iconAfter={ApplicationsIcon} className="service-button">
      <div>
          Software Engineering
          </div>
        </Button>
          <Button onClick={() => this.setState({currentService:4}) } size="large" marginY={8} iconAfter={DatabaseIcon} className="service-button">
          <div>
              Back-End Development
              </div>
            </Button>
              {button}
    </div>
    {currentService()}
    </div>
  );
}

}

export default Services;
